/* global navigator */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../contexts/UserContext';
import {
	fetchProfileBrokerAccounts,
	fetchProfileBrokerAccountsPortfolio,
	sendPaymentInformation,
} from '../../../utils/api';
import formatAccountNumber from '../../../utils/formatAccountNumber';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { SelectField } from '../../forms';
import { Col, Row } from '../../layout';
import {
	Alert,
	Badge,
	Button,
	EmailLink,
} from '../../ui';

import styles from './TopUp.module.scss';

const useFetchProfileBrokerAccounts = useFetchAuth(fetchProfileBrokerAccounts);
const useFetchProfileBrokerAccountsPortfolio = useFetchAuth(fetchProfileBrokerAccountsPortfolio);

const copySVG = (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={18} height={18} viewBox="0 0 28 28">
		<path fill="currentColor" d="M18.7 1.2h-14c-1.3 0-2.4 1-2.4 2.3v16.3h2.4V3.5h14V1.2Zm3.5 4.6H9.3C8.1 5.8 7 6.8 7 8.2v16.3c0 1.3 1 2.3 2.3 2.3h12.9c1.3 0 2.3-1 2.3-2.3V8.2c0-1.3-1-2.4-2.3-2.4Zm0 18.7H9.3V8.2h12.9v16.3Z" />
	</svg>
);

export default function TopUp({
	childId,
	defaultPortfolioId,
	employer,
	portfolios,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [user, loadingUser] = useUserContext();
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const [currency, setCurrency] = useState(0);
	const [selectedPortfolioId, setSelectedPortfolioId] = useState(null);
	const portfolioId = selectedPortfolioId ?? defaultPortfolioId ?? portfolios?.[0].id ?? null;

	const [accounts, loadingAccounts] = useFetchProfileBrokerAccounts(null, language);
	const [accountsPortfolio, loadingAccountsPortfolio] = useFetchProfileBrokerAccountsPortfolio(
		null,
		portfolioId,
		childId,
	);
	const brokerAccounts = accounts?.brokerAccounts ?? null;
	const brokerAccountsPortfolio = accountsPortfolio?.brokerAccounts ?? null;

	const sendPaymentInformationAuth = useRequestAuth(sendPaymentInformation);

	useEffect(() => {
		const defaultBrokerAccount = brokerAccountsPortfolio?.filter((a) => a.default)[0];
		if (brokerAccountsPortfolio && defaultBrokerAccount) {
			setCurrency(brokerAccountsPortfolio.indexOf(defaultBrokerAccount));
		}
	}, [brokerAccountsPortfolio]);

	if (loadingUser || loadingAccounts || loadingAccountsPortfolio || !brokerAccounts || !brokerAccounts.length) {
		return null;
	}

	const qrCode = brokerAccountsPortfolio?.[currency]?.qrCode ?? null;

	const currencies = brokerAccounts.map((brokerAccount, index) => ({
		label: brokerAccount.currency.code,
		value: index,
	}));

	const portfoliosOptions = portfolios !== null ? portfolios.filter(({ blocked }) => !blocked).map((portfolio) => ({
		label:
		<>
			<img
				src={`${process.env.PUBLIC_URL}/images/faces/${portfolio.faceImageFile}`}
				alt={portfolio.name}
				width={40}
				height={40}
			/>
			{filterChild(portfolio.child, user) && (
				<span className={styles.badgeWrap}>
					<Badge label={portfolio.child.firstName} size="tiny" variant="orange" />
				</span>
			)}
			{portfolio.categoryName === 'pension' && (
				<span className={styles.badgeWrap}>
					<Badge label={t('productSelect.pension')} size="tiny" variant="wine" />
					{country === 'CZ' && <Badge label="DIP" size="tiny" variant="wine" />}
				</span>
			)}
			<span>{portfolio.name}</span>
		</>,
		value: portfolio.id,
	})) : [];

	const userAccounts = Object.values(user?.bank_accounts ?? {}).filter((a) => a.status !== 2);

	const selectedPortfolio = portfolios?.find((portfolio) => portfolio.id === portfolioId);
	const isPensionPortfolio = selectedPortfolio?.categoryName === 'pension';
	const isChildPortfolio = selectedPortfolio?.child !== null;

	const copyToClipboard = (content) => {
		navigator.clipboard.writeText(content);
	};

	const handleSendPaymentInformation = async () => {
		setError(false);
		setSuccess(false);
		setLoading(true);
		try {
			await sendPaymentInformationAuth(
				selectedPortfolioId || portfolioId,
				language,
				currencies[currency].label,
			);
		} catch (e) {
			setError(true);
			return;
		} finally {
			setLoading(false);
		}
		setSuccess(true);
	};

	return (
		<div className={styles.root}>
			<Row>
				{qrCode !== null && (
					<Col lg={5}>
						<div className={styles.qr}>
							<div className={styles.qrTitle}>
								{t('topUp.qr')}
							</div>
							<img
								className={styles.qrImage}
								src={qrCode}
								alt={t('topUp.qr')}
							/>
						</div>
					</Col>
				)}
				<Col lg={qrCode !== null ? 7 : null}>
					<div className={styles.wrap}>
						{currencies.length > 1 && (
							<div className={styles.currency}>
								<SelectField
									id="currency"
									label={t('topUp.currency')}
									options={currencies}
									name="currency"
									value={currency}
									onChange={(_, value) => setCurrency(value)}
								/>
							</div>
						)}
						{portfoliosOptions.length > 1 && (
							<div className={styles.portfolio}>
								<SelectField
									id="portfolio"
									label={t('topUp.portfolio')}
									onChange={(_, value) => setSelectedPortfolioId(value)}
									options={portfoliosOptions}
									name="portfolio"
									value={portfolioId}
								/>
							</div>
						)}
					</div>
					<div className={styles.payment}>
						<h4 className={styles.paymentTitle}>
							{t('topUp.payment.title')}
						</h4>
						<div>
							<div className={styles.accountTitle}>{t('topUp.payment.account.title')}</div>
							<div className={styles.accountNumber}>
								{formatAccountNumber(brokerAccounts[currency], country)}
								<button
									className={styles.copyButton}
									type="button"
									onClick={() => copyToClipboard(
										formatAccountNumber(brokerAccounts[currency], country),
									)}
								>
									{copySVG}
								</button>
							</div>
							<div className={styles.accountInfo}>{t('topUp.payment.account.info')}</div>
						</div>
						<div className={styles.accountInternational}>
							<div className={styles.accountInternationalItem}>
								<div className={styles.accountInternationalTitle}>
									{t('topUp.payment.account.iban')}
								</div>
								<div className={styles.accountInternationalNumber}>
									{brokerAccounts[currency]?.iban}
									<button
										className={`${styles.copyButton} ${styles.small}`.trim()}
										type="button"
										onClick={() => copyToClipboard(brokerAccounts[currency]?.iban)}
									>
										{copySVG}
									</button>
								</div>
							</div>
							<div className={styles.accountInternationalItem}>
								<div className={styles.accountInternationalTitle}>
									{t('topUp.payment.account.swift')}
								</div>
								<div className={styles.accountInternationalNumber}>
									{brokerAccounts[currency]?.swift}
									<button
										className={`${styles.copyButton} ${styles.small}`.trim()}
										type="button"
										onClick={() => copyToClipboard(brokerAccounts[currency]?.swift)}
									>
										{copySVG}
									</button>
								</div>
							</div>
						</div>
						<div className={styles.note}>
							<div className={styles.noteTitle}>{employer ? t('topUp.employer.payment.note') : t('topUp.payment.note')}</div>
							<div className={styles.noteContent}>
								{portfolioId}
								<button
									className={`${styles.copyButton} ${styles.small}`.trim()}
									type="button"
									onClick={() => copyToClipboard(portfolioId)}
								>
									{copySVG}
								</button>
							</div>
						</div>
						<address className={styles.address}>
							<p className={styles.addressItem}>{t('topUp.payment.info.owner')}</p>
							<p className={styles.addressItem}>{t('topUp.payment.info.address')}</p>
						</address>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					{userAccounts.length > 0 && !employer && (
						<div className={styles.warning}>
							<svg
								height={18}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
							>
								<path
									fill="currentColor"
									d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
								/>
							</svg>
							{isChildPortfolio ? (
								<Trans i18nKey="topUp.payment.warning.accountChild">
									<strong />
								</Trans>
							) : t('topUp.payment.warning.account', { count: userAccounts.length })}
							{userAccounts.map((account) => (
								<div key={formatAccountNumber(account, country)}>
									<strong>
										{formatAccountNumber(account, country)}
									</strong>
								</div>
							))}
						</div>
					)}
					{t('topUp.payment.warning.transfer') !== '' && (
						<div className={styles.warning}>
							<svg
								height={18}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
							>
								<path
									fill="currentColor"
									d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
								/>
							</svg>
							<Trans i18nKey="topUp.payment.warning.transfer">
								<strong />
							</Trans>
						</div>
					)}

					{isChildPortfolio && (
						<div className={styles.warning}>
							<svg
								height={18}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
							>
								<path
									fill="currentColor"
									d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
								/>
							</svg>
							<Trans i18nKey="topUp.payment.warning.child">
								<strong />
							</Trans>
						</div>
					)}

					{!employer && (
						<div className={styles.warning}>
							<svg
								height={18}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
							>
								<path
									fill="currentColor"
									d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
								/>
							</svg>
							<Trans i18nKey="topUp.payment.warning.banks">
								<strong />
								<EmailLink />
							</Trans>
						</div>
					)}

					{(
						isPensionPortfolio
						&& t('topUp.payment.warning.pension') !== ''
						&& t('topUp.payment.warning.employerNote') !== ''
					) && (
						<>
							<div className={styles.warning}>
								<svg
									height={18}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 576 512"
								>
									<path
										fill="currentColor"
										d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
									/>
								</svg>
								<Trans i18nKey="topUp.payment.warning.pension">
									<strong />
								</Trans>
							</div>
							<div className={styles.warning}>
								<svg
									height={18}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 576 512"
								>
									<path
										fill="currentColor"
										d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
									/>
								</svg>
								<Trans i18nKey="topUp.payment.warning.employerNote">
									<strong />
								</Trans>
							</div>
						</>
					)}

					<div className={styles.warning}>
						<svg
							height={18}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 576 512"
						>
							<path
								fill="currentColor"
								d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
							/>
						</svg>
						{employer ? t('topUp.employer.payment.warning.note') : t('topUp.payment.warning.note')}
					</div>
				</Col>
			</Row>
			{employer && (
				<>
					<Row center>
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						{success && (
							<Alert type="success">
								{t('topUp.employer.modal.success')}
							</Alert>
						)}
					</Row>
					<Row center>
						<Button
							disabled={loading}
							label={t('topUp.employer.modal.send')}
							onClick={handleSendPaymentInformation}
						/>
					</Row>
				</>
			)}
		</div>
	);
}

TopUp.propTypes = {
	childId: PropTypes.number,
	defaultPortfolioId: PropTypes.number,
	employer: PropTypes.bool,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
		faceImageFile: PropTypes.string.isRequired,
	}).isRequired),
};

TopUp.defaultProps = {
	childId: null,
	defaultPortfolioId: null,
	employer: false,
	portfolios: null,
};
